<template>
  <div class="content">
    <p>{{$t("index.advantage")}}</p>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#114996">
      <van-swipe-item v-for="(item, i) in 3" :key="i">
        <van-image
          :src="require(`@/assets/cls/advantage${i + 1}.png`)"
          width="102px"
          height="87px"
        />
        <div class="word">{{ $t(`index.index${i+1}_Text`) }}</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  margin-bottom: 33px;
  p {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 16px;
  }
  .my-swipe {
    height: 188px;
    background: #f3f9ff;
    border-radius: 10px;
    /deep/.van-swipe__indicator {
      background: #a8a8a8;
    }
    .van-swipe-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 0 12px;

      .word {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #114996;
        margin-top: 22px;
      }
    }
  }
}
</style>