<template>
  <!-- Project link -->
  <div class="realtime mx-15">
    <p class="bold fs-xxl">{{ $t("home.link") }}</p>
    <div class="list borders m-t-10">
      <div class="list_item mx-20 d-flex ai-center jc-between">
        <p class="t-color2 fs-lg">{{ $t("home.contractAddress") }}</p>
        <div class="d-flex ai-center">
          <van-field
            type="text"
            readonly
            :border="false"
            :value="contractAddress"
            class="input"
          ></van-field>
          <van-image
            width="15px"
            height="15px"
            :src="require('@/assets/copy.png')"
            v-clipboard:copy="contractAddress"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          />
        </div>
      </div>
      <div class="list_item mx-20 d-flex ai-center jc-between">
        <p class="t-color2 fs-lg">{{ $t("home.invitation") }}</p>
        <div class="d-flex ai-center">
          <van-field
            type="text"
            readonly
            :border="false"
            :value="invitation"
            class="input"
          ></van-field>
          <van-image
            width="15px"
            height="15px"
            :src="require('@/assets/copy.png')"
            v-clipboard:copy="invitation"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          />
        </div>
      </div>
      <div class="list_item mx-20 d-flex ai-center jc-between">
        <p class="t-color2 fs-lg">{{ $t("home.peopleof") }}</p>
        <p class="m-r-5 fs-md">{{ people }} {{ $t("home.people") }}</p>
      </div>
    </div>
    <div class="m-t-15 d-flex flex-column ai-center jc-center">
      <van-image
        width="37px"
        height="48px"
        :src="require('@/assets/logo.png')"
      />
      <p class="bold fs-xxl m-t-5">UANG NFT</p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "REALTIME",
  props: {},
  data() {
    return {
      loading: true,
      contractAddress: "--", //合约地址
      invitation: "--", //邀请链接
      people: null, //合约地址
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      setting: (state) => state.setting,
    }),
  },
  created() {
    this.$store.dispatch("getUserinfo"); //用户信息
    this.$store.dispatch("getSetting"); //网站配置
  },
  mounted() {
    if (this.$storage.get("devotokens") != undefined) {
      this.getuserinfo();
    }
  },
  methods: {
    more() {},
    //复制成功
    onCopy() {
      this.$toast({
        message: this.$t("public.copySuc"),
        duration: 1000,
      });
    },
    //复制失败
    onError() {
      this.$toast({
        message: this.$t("public.copyCancel"),
        duration: 1000,
      });
    },
    getuserinfo() {
      this.$axios.get(this.$api.setting, {}).then((res) => {
        console.log(5665);
        res.data.forEach((element) => {
          if (element.id == 2) {
            this.contractAddress = element.value;
          }
        });
      });
      // console.log(JSON.parse(this.userinfo));
      let host = "http://" + window.location.host + "?code=";
      this.$axios.get(this.$api.userinfo, {}).then((res) => {
        console.log(res);
        this.invitation = host + res.data.invite_code;
        this.people = res.data.team_people;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-cell{
  padding: 0;
}
/deep/ .van-field__control{
  text-align: right;
}
.realtime {
  .list {
    height: 125px;
    .list_item {
      line-height: 40px;
      border-bottom: 1px solid #15a3ee;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.input {
  background-color: transparent;
}
</style>