<template>
  <div class="content">
    <div class="total">
      <div class="item">
        <div class="img" style="background-color:#00A8FF">
          <van-image width="31px" height="39px" :src="require('@/assets/cls/overview1.png')" />
        </div>
        <div class="msg">
          <div style="text-align:center">{{$t("index.volume")}}</div>
          <div style="text-align:center">${{msg.total_trading_volume}}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="img" style="background-color:#1DC872">
          <van-image width="37px" height="37px" :src="require('@/assets/cls/overview1.png')" />
        </div>
        <div class="msg">
          <div style="text-align:center">{{$t("index.transactionVolume")}}</div>
          <div style="text-align:center">${{msg.trading_volume_24h}}</div>
        </div>
      </div>

      <div class="item">
        <div class="img" style="background-color:#7111EC">
          <van-image width="36px" height="33px" :src="require('@/assets/cls/overview3.png')" />
        </div>
        <div class="msg">
          <div style="text-align:center">{{$t("index.user")}}</div>
          <div style="text-align:center">{{msg.active_users}}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="img" style="background-color:#F3CE1B">
          <van-image width="36px" height="34px" :src="require('@/assets/cls/overview4.png')" />
        </div>
        <div class="msg">
          <div style="text-align:center">{{$t("index.dividend")}}</div>
          <div style="text-align:center">${{msg.node_share_bonus}}</div>
        </div>
      </div>

      <div class="item">
        <div class="img" style="background-color:#F93B5D">
          <van-image width="37px" height="28px" :src="require('@/assets/cls/overview5.png')" />
        </div>
        <div class="msg">
          <div style="text-align:center">{{$t("index.acquire")}}</div>
          <div style="text-align:center">{{msg.obtain_cls_num}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: {}
    };
  },
  methods: {
    msgHandle() {
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach(item => {
        if (item.key == "total_trading_volume") {
          this.msg.total_trading_volume = item.value;
        }
        if (item.key == "24h_trading_volume") {
          this.msg.trading_volume_24h = item.value;
        }
        if (item.key == "active_users") {
          this.msg.active_users = item.value;
        }
        if (item.key == "node_share_bonus") {
          this.msg.node_share_bonus = item.value;
        }
        if (item.key == "obtain_cls_num") {
          this.msg.obtain_cls_num = item.value;
        }
      });
    }
  },
  created() {
    this.msgHandle();
  }
};
</script>
<style lang="scss" scoped>
.content {
  .total {
    background: #f3f9ff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 36px 0;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 49.5%;
      margin-bottom: 34px;
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin-bottom: 10px;
        border-radius: 10px;
      }
      .msg {
        div:first-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #114996;
          margin-bottom: 11px;
        }
        div:last-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .line {
      width: 1px;
      height: 80px;
      background-color: #00a8ff;
      opacity: 0.5;
      transform: translateY(-17px);
    }
  }
}
</style>