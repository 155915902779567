<template>
  <!-- GameFi Market -->
  <div class="realtime mx-15">
    <div class="d-flex ai-center jc-between">
      <p class="bold fs-xxl">GameFi {{ $t("home.market") }}</p>
      <p class="fs-md t-color2" @click="more">
        {{ $t("home.more") }}<van-icon name="arrow" />
      </p>
    </div>
    <div class="m-t-10 listbox">
      <div
        class="item m-r-10"
        v-for="(item, index) in list"
        :key="index"
        @click="toDetail(item)"
      >
        <van-image
          fit="cover"
          width="auto"
          height="100px"
          round
          radius="10px"
          :src="item.remote_url"
        />
        <p class="fs-min t-color3 m-t-5 t-center">
          {{ lang == "zh" ? item.name : item.name_en }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "REALTIME",
  props: {},
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  methods: {
    more() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/gamefi");
      }
    },
    //游戏列表
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.gamefilist, {
          page: 1,
          page_size: 8,
        })
        .then((res) => {
          this.list = res.data.list;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //游戏详情
    toDetail(item) {
      this.$router.push("/gameDetail?id=" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.realtime {
  .listbox {
    min-height: 130px;
    overflow-x: auto;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      width: 100px;
      display: inline-block;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>