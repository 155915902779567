<template>
  <div class="content">
    <p>{{ $t("index.commentText") }}</p>
    <div class="subhead">{{ $t("index.Indetail") }}</div>
    <div class="btn">
      <div class="arrow" @click="swiperHandle('prev')">
        <van-image
          width="12px"
          height="9px"
          :src="require('@/assets/cls/info-al.png')"
        />
      </div>
      <div class="arrow" style="margin-left: 5px" @click="swiperHandle('next')">
        <van-image
          width="12px"
          height="9px"
          :src="require('@/assets/cls/info-ar.png')"
        />
      </div>
    </div>

    <van-swipe class="my-swipe" :show-indicators="false" ref="swipe">
      <van-swipe-item v-for="(item, i) in 3" :key="i" height="100%">
        <van-image
          width="139px"
          height="79px"
          :src="require(`@/assets/cls/invite-img.png`)"
        />
        <div class="text">{{ $t(`index.info_text${i+1}`) }}</div>
      </van-swipe-item>
    </van-swipe>
    <div class="investor">
      <van-image
        width="45px"
        height="45px"
        :src="require('@/assets/cls/binance.png')"
      />
      <div class="name">BINANCE</div>
      <div class="pet-name">LABS</div>
      <div class="title">{{ $t("index.invest") }}</div>
      <div class="word">{{ $t("index.laboratory") }}</div>
    </div>
    <div class="introduce">
      <van-image
        width="100%"
        height="140px"
        :src="require('@/assets/cls/index-bottom-bg.png')"
      />
      <div class="question">{{ $t("index.collins") }}</div>
      <div class="answer">{{ $t("index.explain") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
  methods: {
    swiperHandle(type) {
      console.log(12);
      if (type == "prev") {
        this.$refs.swipe.prev();
      } else {
        this.$refs.swipe.next();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 24px 15px 21px;
  background: linear-gradient(140deg, #163876, #2a5298);
  p {
    margin-left: 8px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
  .subhead {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #97cbff;
    margin: 14px 0 15px 8px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 21px;
    .arrow {
      width: 30px;
      height: 25px;
      background: #135ec5;
      border-radius: 5px;
      line-height: 25px;
      text-align: center;
    }
  }
  .my-swipe {
 
    box-sizing: border-box;
    margin: 0 0 18px 0;
    background: linear-gradient(140deg, #163876, #2a5298);
    border-radius: 10px;
    padding: 18px 0 21px 31px;
    .text{
      font-size: 12px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
margin-top: 11px;

line-height: 24px;
    }
  }
  .investor {
    margin: 0 0 14px;
    background: url("../../assets/cls/investor-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 30px 0 31px;
    text-align: center;
    .name {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #f3ba2f;
      margin: 8px 0;
    }
    .pet-name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #f3ba2f;
      margin: 8px 0 44px;
    }
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .word {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #96cbff;
      line-height: 18px;
      padding: 0 39px 0 39px;
      margin-top: 15px;
    }
  }
  .introduce {
    /deep/.van-image__img {
      border-radius: 10px;
    }

    border: 1px solid #0e5ce7;
    border-radius: 10px;
    padding: 19px 18px 21px;
    text-align: center;
    .question {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin: 27px 0 16px;
    }
    .answer {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #96cbff;
      line-height: 18px;
    }
  }
}
</style>