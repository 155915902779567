<template>
  <div class="content">
    <div class="list">
      <div class="item-box">
        <div class="title">
          <div>{{$t("index.contract")}}</div>
          <div class="arrow" @click="toPledge">
            <van-image width="12px" height="9px" :src="require('@/assets/cls/arrow-right.png')" />
          </div>
        </div>
        <div class="item">
          <div class="name">
            <van-image width="20px" height="20px" :src="require('@/assets/cls/usdt-logo.png')" />
            <span>USDT</span>
          </div>
          <div class="contract">
            <div>
              <div>{{$t("index.interest")}}</div>
              <div style="font-weight: bold;color: #00A8FF;margin-top:5px">{{msg.contract_interest_rate_24h}}%</div>
            </div>
            <van-button size="small" color="#00A8FF" class="bold" @click="toPledge">{{$t("index.startContract")}}</van-button>
          </div>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <div>{{$t("index.moonContract")}}</div>
          <div class="arrow" @click="toPledge">
            <van-image width="12px" height="9px" :src="require('@/assets/cls/arrow-right.png')" />
          </div>
        </div>
        <div class="item">
          <div class="name">
            <van-image width="20px" height="20px" :src="require('@/assets/cls/usdt-logo.png')" />
            <span>USDT</span>
          </div>
          <div class="contract">
            <div>
              <div>{{$t("index.interest")}}</div>
              <div style="font-weight: bold;color: #00A8FF;margin-top:5px">{{msg.contract_interest_rate_30d}}%</div>
            </div>
            <van-button size="small" color="#00A8FF" class="bold" @click="toPledge">{{$t("index.startContract")}}</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  

  data() {
    return {
     msg:{}
    };
  },
  methods: {
    toPledge(e) {
      this.$router.push('/pledge')
    },
    msgHandle() {
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach(item => {
        if (item.key == "24h_contract_interest_rate") {
          this.msg.contract_interest_rate_24h = item.value;
        }
        if (item.key == "30day_contract_interest_rate") {
          this.msg.contract_interest_rate_30d = item.value;
        }
      });
    }
  },
  created() {
    this.msgHandle();
  }
};
</script>
<style lang="scss" scoped>
.content {
  .list {
    .item-box {
      margin-bottom: 31px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        div:first-child {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .arrow {
          width: 30px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background: #ecf5ff;
          border: 1px solid #00a8ff;
          border-radius: 5px;
        }
      }
      .item {
        height: 98px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 15px;
        .name {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
          span {
            margin-left: 6px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
        }
        .contract {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div:first-child {
            div {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>