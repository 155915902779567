<template>
  <div class="content">
    <!-- <div class="best">
      <div class="item">
        <van-image width="35px" height="34px" :src="require('@/assets/cls/best-left.png')" />
        <div class="msg">
          <div>{{$t("index.storage")}}</div>
          <div>1.2%</div>
        </div>
      </div>
      <div class="item">
        <van-image width="35px" height="34px" :src="require('@/assets/cls/best-right.png')" />
        <div class="msg">
          <div>{{$t("index.model")}}</div>
          <div>{{$t("index.high")}} 60%</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="total">
      <div class="item">
        <van-image
          width="30px"
          height="34px"
          :src="require('@/assets/cls/binance.png')"
          style="margin-right:16px"
        />
        <div class="msg">
          <div>{{$t("index.LockValue")}}</div>
          <div>${{ totalMsg.total_lock_money }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="msg">
          <div>{{$t("index.volume")}}</div>
          <div>${{totalMsg.total_trading_volume}}</div>
        </div>
      </div>
    </div> -->
    <div class="coin-msg">
      <div class="coin-bottomBorder">
        <div class="item" v-for="item in info" :key="item.symbol">
          <van-image
            v-if="item.symbol == 'ETH'"
            width="31px"
            height="31px"
            :src="require('@/assets/cls/eth.png')"
          />
          <van-image
            v-if="item.symbol == 'UNA'"
            width="31px"
            height="31px"
            :src="require('@/assets/xxImage/index/uanx.png')"
          />
          <van-image
            v-if="item.symbol == 'BTC'"
            width="31px"
            height="31px"
            :src="require('@/assets/cls/btcb.png')"
          />
          <van-image
            v-if="item.symbol == 'BNB'"
            width="31px"
            height="31px"
            fit="cover"
            :src="require('@/assets/cls/wbnb.png')"
          />
          <div class="msg">
            <div>
              <span class="msg_title">{{ item.symbol }}</span>
              <span
                style="margin-left: 5px"
                :class="
                  item.priceChange.toString().indexOf('-') == -1 ? 'add' : 'subtract'
                "
                >{{
                  item.priceChange.toString().indexOf("-") == -1
                    ? "+" + item.priceChange
                    : item.priceChange
                }}%</span
              >
            </div>
            <div style="color:white;">${{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { json } from "body-parser";

export default {
  props: ["info"],
  data() {
    return {
      totalMsg: {},
    };
  },
  methods: {
    totalMsgHandle() {
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach((item) => {
        if (item.key == "total_lock_money") {
          this.totalMsg.total_lock_money = item.value;
        }
        if (item.key == "total_trading_volume") {
          this.totalMsg.total_trading_volume = item.value;
        }
      });
    },
  },
  created() {
    this.totalMsgHandle();
  },
};
</script>
<style lang="scss" scoped>
.content {
  .best {
    margin-bottom: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      display: flex;
      align-items: center;
      .msg {
        margin-left: 8px;
        div:first-child {
          font-size: 10px;
          font-family: Source Han Sans CN;
          margin-bottom: 6px;
          font-weight: 500;
          color: #ffffff;
        }
        div:last-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #e6a23c;
        }
      }
    }
  }
  .total {
    height: 74px;
    background: #2860cb;
    border-radius: 10px;
    margin-bottom: 29px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    .item {
      display: flex;
      align-items: center;
      .msg {
        div:first-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          margin-bottom: 7px;
          font-weight: 400;
          color: #96cbff;
        }
        div:last-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .line {
      width: 1px;
      height: 43px;
      background-color: #ffffff;
      opacity: 0.5;
      margin: 0 30px 0 33px;
    }
  }
  .coin-msg {
    width: 100%;
    height: 121px;
    .coin-bottomBorder{
      width: 100%;
      height: 116px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .item {
      width: 43%;
      display: flex;
      align-items: center;
      // margin-bottom: 23px;
      .msg {
        .msg_title {
          color: white;
        }
        .subtract {
          white-space:nowrap;
          color: #f56c6c;
        }
        .add {
          color: #67c23a;
        }
        margin-left: 10px;
        div:first-child {
          font-size: 14px;
          font-family: Source Han Sans CN;
          margin-bottom: 7px;
          font-weight: 400;
          color: #96cbff;
        }
        div:last-child {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
}
</style>