<template>
  <!-- Card store -->
  <div class="realtime">
    <div class="d-flex ai-center jc-between mx-15">
      <p class="bold fs-xxl">{{ $t("home.cardStore") }}</p>
      <p class="fs-md t-color2" @click="more">
        {{ $t("home.more") }}<van-icon name="arrow" />
      </p>
    </div>
    <div class="swiper-container my-15" v-if="list.length > 0">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide d-flex flex-cloumn ai-center"
          v-for="(item, index) in list"
          :key="index"
          @click="toCardDetail(item)"
        >
          <img
            :src="require('@/assets/gif/border' + item.level + '.png')"
            style="width: 100%; height: 100%"
          />
          <img
            :src="item.cardInfo.remote_url"
            style="width: 100%; height: 100%"
            class="swiper-lazy img p-5"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  name: "REALTIME",
  props: {},
  data() {
    return {
      loading: true,
      level: 1,
      list: [],
    };
  },
  mounted() {
    this.getList();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    newcreateSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        autoplay: 3000, //可选选项，自动滑动
        autoplayDisableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        observer: true,
        spaceBetween: 30,
        slidesPerView: "auto",
        centeredSlides: true, //每屏，居中显示
        // loop: true, // 循环模式选项
        initialSlide: 0, //轮播图初始下标位置
        preloadImages: true,
        // lazyLoading: true,
        // lazyLoadingInPrevNext: true,
        // lazyLoadingInPrevNextAmount: 10, // 设置在延迟加载图片时提前多少个slide
      });
    },
    createSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        autoplay: 3000, //可选选项，自动滑动
        autoplayDisableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        observer: true,
        observeParents: false,
        spaceBetween: 30,
        slidesPerView: "auto",
        centeredSlides: true, //每屏，居中显示
        loop: true, // 循环模式选项
        initialSlide: 1, //轮播图初始下标位置
        preloadImages: true,
        onSlideChangeEnd: function (swiper) {
          // swiper.update();
          // swiper.startAutoplay();
          // swiper.reLoop();
        },
        // lazyLoading: true,
        // lazyLoadingInPrevNext: true,
        // lazyLoadingInPrevNextAmount: 10, // 设置在延迟加载图片时提前多少个slide
      });
    },
    more() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/cardStore");
      }
    },
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.mallCards, {
          page: 1,
          page_size: 8,
          sort_type: 1,
        })
        .then((res) => {
          this.list = res.data.list;
          this.loading = false;
          this.$nextTick(() => {
            if (this.list.length <= 1) {
              this.newcreateSwiper();
            } else {
              this.createSwiper();
            }
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //跳转卡片详情
    toCardDetail(item) {
      let value = JSON.stringify(item);
      this.$router.push({
        path: "/cardDetail",
        query: { value: value },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.realtime {
  .swiper-slide {
    width: 154px;
    height: 224px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    position: relative;
    .img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
  }
}
</style>