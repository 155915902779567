<template>
  <!-- GameFi News -->
  <div class="realtime mx-15">
    <div class="d-flex ai-center jc-between">
      <p class="bold fs-xxl">GameFi {{ $t("home.news") }}</p>
      <p class="fs-md t-color2" @click="more">
        {{ $t("home.more") }}<van-icon name="arrow" />
      </p>
    </div>
    <div class="list m-t-10 d-flex jc-between">
      <div
        class="list_item m-r-10"
        v-for="(item, index) in list"
        :key="index"
        @click="todetail(item)"
      >
        <van-image width="100%" height="80px" :src="item.cover_remote_url" />
        <div class="cotent_text d-flex flex-column ai-center jc-center p-5">
          <van-skeleton :row="2" :loading="loading" row-width="130px">
            <p class="fs-sm t-center w-100">
              {{ lang == "zh" ? item.title : item.title_en }}
            </p>
            <p class="t-color3 t-center m-t-3 w-100" style="font-size: 8px">
              {{ lang == "zh" ? item.content : item.content_en | getPlainText }}
            </p>
          </van-skeleton>
        </div>
      </div>
    </div>
    <div class="community m-t-10 d-flex ai-center jc-between">
      <p class="fs-lg m-l-20">{{ $t("home.community") }}</p>
      <div class="d-flex ai-center m-r-20">
        <van-image
          width="21px"
          height="21px"
          :src="require('@/assets/twitter.png')"
          @click="openLink(twitter_url)"
        />
        <van-image
          width="21px"
          height="21px"
          class="m-l-10"
          :src="require('@/assets/telegram.png')"
          @click="openLink(telegram_url)"
        />
        <van-image
          width="21px"
          height="21px"
          class="m-l-10"
          :src="require('@/assets/discord.png')"
          @click="openLink(disco_url)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "REALTIME",
  props: {},
  data() {
    return {
      loading: true,
      list: [],
      twitter_url: "", //推特地址
      telegram_url: "", //电报地址
      disco_url: "", //https://discord.gg/4BHQtMx9
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      setting: (state) => state.setting,
    }),
    lang() {
      return this.$storage.get("lang");
    },
  },
  created() {
    this.$store.dispatch("getUserinfo"); //用户信息
    this.$store.dispatch("getSetting"); //网站配置
  },
  mounted() {
    this.getSet();
    this.getList();
    if (this.$storage.get("devotokens") != undefined) {
      this.getuserinfo();
    }
  },
  methods: {
    more() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/news");
      }
    },
     //获取网站配置
    getSet() {
      if (this.setting) {
        let set = JSON.parse(this.setting);
        set.forEach((element) => {
          if (element.id == 59) {
            //discard链接
            this.disco_url = element.value;
          }
        });
      }
    },
    //资讯列表
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.newslist + "/1", {
          page: 1,
          page_size: 8,
        })
        .then((res) => {
          // res.data.list.forEach((element) => {
          //   console.log(this.getPlainText(element.content));
          // });
          if (res.data.list.length > 2) {
            this.list = res.data.list.splice(2);
          } else {
            this.list = res.data.list;
          }
          this.loading = false;
        }).catch(err=>{
            this.loading = false;
        })
    },
    getuserinfo() {
      // console.log(this.userinfo);
      this.$axios.get(this.$api.userinfo, {}).then((res) => {
        this.twitter_url = res.data.twitter_url;
        this.telegram_url = res.data.telegram_url;
      });
    },
    todetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: { id: item.id, type: 1 },
      });
    },
    openLink(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.realtime {
  .list {
    width: 100%;
    .list_item {
      width: 50%;
      min-height: 120px;
      border-radius: 10px;
      overflow: hidden;
      font-size: 0;
      &:last-child {
        margin-right: 0px;
      }
      .cotent_text {
        min-height: 45px;
        background-color: #28527a;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .community {
    height: 40px;
    padding: 0;
    border-radius: 10px;
    border: 2px solid transparent;
    background-image: linear-gradient(#0d182a, #0d182a),
      linear-gradient(267deg, #15a3ee, #391aeb);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
</style>