<template>
  <!-- My NFT Card -->
  <div class="realtime mx-15">
    <div class="d-flex ai-center jc-between">
      <p class="bold fs-xxl">{{ $t("home.myNFTCard") }}</p>
      <p class="fs-md t-color2" @click="more">
        {{ $t("home.more") }}<van-icon name="arrow" />
      </p>
    </div>
    <div class="m-t-15 d-flex flex-wrap jc-between">
      <div class="griditem borders m-b-15 d-flex ai-center" @click="todetail('/myNFTcard')">
        <van-image
          width="25px"
          height="33px"
          class="m-r-10 m-l-15"
          :src="require('@/assets/icon_card.png')"
        />
        <div class="m-r-15">
          <van-skeleton :row="1" :loading="loading" row-width="50px">
            <p class="bold">
              <span class="fs-lg">{{ total }}</span>
            </p>
          </van-skeleton>
          <p class="t-color2 fs-sm m-t-3">{{ $t("home.quantity") }}</p>
        </div>
      </div>
      <div class="griditem borders m-b-15 d-flex ai-center" @click="todetail('/myNFTcard')">
        <van-image
          width="40px"
          height="35px"
          class="m-r-10 m-l-15"
          :src="require('@/assets/icons_staking.png')"
        />
        <div class="m-r-15">
          <van-skeleton :row="1" :loading="loading" row-width="50px">
            <p class="bold">
              <span class="fs-lg">{{ pledge_count }}</span>
            </p>
          </van-skeleton>
          <p class="t-color2 fs-sm m-t-3">{{ $t("home.cardsNum") }}</p>
        </div>
      </div>
      <div class="griditem borders m-b-15 d-flex ai-center" @click="todetail('/myNFTcard')">
        <van-image
          width="29px"
          height="29px"
          class="m-r-10 m-l-15"
          :src="require('@/assets/icons_sale.png')"
        />
        <div class="m-r-15">
          <van-skeleton :row="1" :loading="loading" row-width="50px">
            <p class="bold">
              <span class="fs-lg">{{ sale_count }}</span>
            </p>
          </van-skeleton>
          <p class="t-color2 fs-sm m-t-3">{{ $t("home.forsale") }}</p>
        </div>
      </div>
      <div class="griditem borders m-b-15 d-flex ai-center" @click="todetail('/myNFTcard')">
        <van-image
          width="33px"
          height="27px"
          class="m-r-10 m-l-15"
          :src="require('@/assets/icons_exchange.png')"
        />
        <div class="m-r-15">
          <van-skeleton :row="1" :loading="loading" row-width="50px">
            <p class="bold">
              <span class="fs-lg">{{ earn | cutZero }}</span
              ><span class="fs-lg m-l-3">ACDC</span>
            </p>
          </van-skeleton>
          <p class="t-color2 fs-sm m-t-3">{{ $t("home.earnings") }}</p>
        </div>
      </div>
    </div>
    <div class="buynow d-flex flex-column ai-center jc-center">
      <p class="fs-lg t-center lh-2">
        <span class="fs-xxl">UANG NFT</span> {{ $t("home.card") }}<br />
        {{ $t("home.tips3") }}
      </p>
      <div
        class="buy_btn d-flex ai-center jc-center t-center"
        @click="toBindbox"
      >
        <p class="fs-lg">
          {{ $t("home.extract") }}<br />{{ $t("home.extract2") }}
        </p>
      </div>
    </div>
    <div class="d-flex ai-center jc-between m-t-15">
      <van-button color="#074CE7" class="btn" @click="tosynthetic">{{
        $t("home.synthesize")
      }}</van-button>
      <van-button color="#074CE7" class="btn" @click="toStake">{{
        $t("home.stake")
      }}</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "REALTIME",
  props: {},
  data() {
    return {
      total: "",
      pledge_count: "",
      sale_count: "",
      earn: "",
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.getValue();
  },
  methods: {
    getValue() {
      this.loading = true;
      this.$axios
        .get(this.$api.usercards, {
          page: 1,
          page_size: 8,
        })
        .then((res) => {
          this.total = res.data.total; //卡牌数量
          this.pledge_count = res.data.pledge_count; //质押卡牌数量
          this.sale_count = res.data.sale_count; //正在出售数量
          this.earn = res.data.earn; //我的收益
          this.loading = false;
        });
    },
    more() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/myNFTcard");
      }
    },
    toBindbox() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/bindBox");
      }
    },
    //跳转合成卡牌
    tosynthetic() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/synthetic");
      }
    },
    toStake() {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push("/myNFTcard");
      }
    },
    todetail(path) {
      if (!this.$store.state.walletAddress) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$storage.get("devotokens")) {
        this.$toast(this.$t("public.login"));
      } else if (!this.$store.state.isLinkBsc) {
        this.$toast(this.$t("public.failLogin"));
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-grid {
  padding: 0 !important;
}
/deep/ .van-grid-item__content {
  background-color: transparent;
  // padding: 10px 0;
}
/deep/ .van-button__text {
  font-size: 12px;
}
.realtime {
  .griditem {
    width: 48%;
    min-height: 60px;
  }
  .buynow {
    height: 200px;
    background: url("~@/assets/cards_bg.png") no-repeat;
    background-size: 100% 100%;
    .buy_btn {
      width: 51px;
      height: 51px;
      background: url("~@/assets/buyow.png") no-repeat;
      background-size: 100% 100%;
      p {
        background: linear-gradient(
          222deg,
          #75f8d2 0%,
          #6096db 50%,
          #2ae3fc 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .btn {
    width: 164px;
    height: 30px;
    border-radius: 5px;
  }
}
</style>