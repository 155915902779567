<template>
  <div class="containers" style="background: none">
    <div
      v-if="modelShows"
      style="z-index: 100"
      class="mobelAll"
      @click="modelShows = false"
    ></div>
    <div class="top">
      <navbar @asideShow="asideFlagFun" @numChange="getNewCount"></navbar>
      <div class="top_introduce">
        <div class="d-flex jc-between" style="margin: 15px">
          <div class="introduce_left">
            <div class="left_text">
              <p class="top_title">{{ $t("index.Play") }}</p>
              <p style="color: white">
                {{ $t("index.playText") }}
              </p>
              <!-- <p style="color: white;font-size:13px">{{ $t("index.playsText") }}</p> -->
            </div>
            <van-button @click="ConnectWallet()" class="connect-btn">
              <span style="color: white; font-size: 12px">{{
                $t("index.Participate")
              }}</span>
            </van-button>
            <coin-msg style="margin-top: 7.5px" :info="info"></coin-msg>
          </div>
        </div>
      </div>
    </div>
    <div class="pledgeEnyaoq">
      <div class="pledges">
        <div class="pledge_item">
          <div class="item_left">
            <!-- <svg-icon icon-class="zhiya" style="font-size: 25px"></svg-icon> -->
            <van-image
              style="margin-left: 0"
              width="45px"
              height="31px"
              :src="require('@/assets/xxImage/index/zhiyass.png')"
            />
            <div class="">
              <p>{{ $t("index.pledges") }}</p>
              <p style="color: black">{{ $t("index.PledgeObtain") }}</p>
            </div>
          </div>
          <div class="right_item" @click="toPledge">
            <!-- <svg-icon
              icon-class="gos"
              style="font-size: 14px; color: white"
            ></svg-icon> -->
            {{ $t("home.go") }}
          </div>
        </div>
      </div>
      <div class="invite">
        <div class="pledge_item">
          <div class="item_left">
            <!-- <svg-icon icon-class="yaoq" style="font-size: 25px"></svg-icon> -->
            <van-image
              style="margin-left: 0"
              width="45px"
              height="31px"
              :src="require('@/assets/xxImage/index/huaduo.png')"
            />
            <div class="">
              <p>{{ $t("index.invite") }}</p>
              <p style="color: black">{{ $t("index.together") }}</p>
            </div>
          </div>
          <div class="right_item" @click="toInvite">
            {{ $t("home.go") }}
            <!-- <svg-icon
              icon-class="gos"
              style="font-size: 14px; color: white"
            ></svg-icon> -->
          </div>
        </div>
      </div>
    </div>
    <div class="brag">
      <div class="brag_left">
        <!-- <svg-icon icon-class="shouxufei" style="font-size: 30px"></svg-icon> -->
        <van-image
          style="margin-left: 0"
          width="35px"
          height="30px"
          :src="require('@/assets/xxImage/index/dianhua.png')"
        />
        <p>{{ $t("index.minimum") }}</p>
      </div>
      <div class="brag_right">
        <!-- <svg-icon icon-class="bizhi" style="font-size: 30px"></svg-icon> -->
        <van-image
          style="margin-left: 0"
          width="35px"
          height="30px"
          :src="require('@/assets/xxImage/index/huojian.png')"
        />
        <p>{{ $t("index.Chain") }}</p>
      </div>
    </div>
    <div class="total">
      <div class="item">
        <van-image
          width="34px"
          height="34px"
          :src="require('@/assets/xxImage/index/shizitou.png')"
          style="margin-right: 16px"
        />
        <div class="msg">
          <div>{{ $t("index.LockValue") }}</div>
          <div>${{ totalMsg.total_lock_money }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="msg">
          <div>{{ $t("index.volume") }}</div>
          <div>${{ totalMsg.total_trading_volume }}</div>
        </div>
      </div>
    </div>

    <div class="XXXimg">
      <div class="thisXXXimg">
        <div class="bottomFuo">
          <p>{{ $t("index.whatIs") }}</p>
          <p>{{ $t("index.developerProgram") }}</p>
          <p>{{ $t("index.Decentralized") }}。</p>
        </div>
      </div>
    </div>
    <div class="community">
      <p>{{ $t("index.community") }}</p>
      <div>
        <van-image
          width="34px"
          height="34px"
          :src="require('@/assets/xxImage/index/dianbao.png')"
          style="margin-right: 16px"
          @click="opento(1)"
        />
        <van-image
          width="34px"
          height="34px"
          :src="require('@/assets/xxImage/index/tuite.png')"
          style="margin-right: 16px"
          @click="opento(2)"
        />
      </div>
    </div>
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
    <div
      v-if="jhshows"
      style="z-index: 100"
      class="mobelAll"
      @click="jhshows = false"
    ></div>
    <div
      v-if="jhshows"
      class="jhshowsw"
      style="width: 270px;    display: flex;
    justify-content: center;
}"
    >
      <div class="jhshow">
        <van-image
          width="105px"
          height="60px"
          :src="require('@/assets/xxImage/index/jihuo.png')"
        />
        <p style="font-weight: bold">{{ $t("index.activate") }}？</p>
        <div class="jhshowBtn">
          <div @click="jhshows = false">
            {{ $t("index.confirmButtonText") }}
          </div>
          <div style="background: #8f93fb; color: white" @click="toJh">
            {{ $t("index.confirmButtonTextY") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VConsole from "../vconsole.js";

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import storage from "storejs";
import ethUtil from "ethereumjs-util";

import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import Web3Modal from "web3modal";
import { ethers } from "ethers";

// import svgIcon from '@/components/index/Svglcon.vue'
import Market from "@/components/index/market";
import News from "@/components/index/news";
import Store from "@/components/index/store";
import Card from "@/components/index/card";
import Link from "@/components/index/link";
import Title from "@/components/index/title";
import coinMsg from "@/components/index/coinMsg";
import Contract from "@/components/index/contract";
import Advantage from "@/components/index/advantage";
import Overview from "@/components/index/overview";
import Info from "@/components/index/info";
import Footer from "@/components/index/footer";
import asidePopup from "@/components/common/asidePopup";
export default {
  inject: ["reload"],

  data() {
    return {
      jhshows: false,
      jhshow: false,
      totalMsg: {},
      current: 0,
      asideFlag: false,
      bannerList: [],
      info: {},
      userInfo: {},
      modelShows: false,
      asideFlags: false,
      title: "",
      langIndex: 0,
      showLang: false, //选择语言下拉框
      showConnect: false, //连接钱包成功后用户下拉框
      langList: [
        {
          index: 0,
          text: "English",
          icon: require("@/assets/en@2x.png"),
        },
        {
          index: 1,
          text: "中文",
          icon: require("@/assets/zh@2x.png"),
        },
      ],
    };
  },
  components: {
    // svgIcon,
    Market,
    News,
    Store,
    Card,
    Link,
    Title,
    coinMsg,
    Contract,
    Advantage,
    Overview,
    Info,
    Footer,
    asidePopup,
  },
  async created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.getMarket();
    // const objs = this.getTrustWalletFromWindow();
    // this.getTW();
    // this.getInfoData();
    let obj = window.localStorage.getItem("walletconnect");
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: this.$t("invite.loding"),
    });
    setTimeout(() => {
      this.$toast.clear();
      this.getInfo();
    }, 1500);
  },
  mounted() {
    // this.getBanner();
    this.totalMsgHandle();
  },
  methods: {
    opento(type) {
      if (type == 1) {
        window.open("https://telegram.org/");
      } else {
        window.open("https://twitter.com/");
      }
    },
    //获取用户信息
    getInfo() {
      if (!window.localStorage.getItem("devotokens")) {
        return;
      }
      this.$axios.get(this.$api.userinfo).then((res) => {
        this.jhshow = res.data.active == 1 ? true : false;
        this.jhshows = res.data.active == 1 ? false : true;
        this.userInfo = res.data
      });
    },
    //跳转个人中心
    toJh() {
      this.$router.push("/personal");
    },
    //跳转质押
    toPledge() {
      if (!this.jhshow) {
        this.$toast(this.$t("index.userFirst"));
        return;
      }
      this.$router.push("/pledge");
    },
    //跳转邀请
    toInvite() {
      if (!this.jhshow) {
        this.$toast(this.$t("index.userFirst"));
        return;
      }
      this.$router.push("/invite");
    },
    //跳转挖矿
    ConnectWallet() {
      if (typeof this.userInfo.active != 'undefined') {
        if (!this.jhshow) {
          this.$toast(this.$t("index.userFirst"));
          return;
        }
        this.$router.push("/mining");
      }
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    toindex() {
      this.$router.push("/index");
    },
    getNewCount(val) {
      if (val) {
        this.modelShows = val;
      } else {
        this.modelShows = !this.modelShows;
      }
    },

    totalMsgHandle() {
      this.$axios.get(this.$api.setting).then((res) => {
        res.data.forEach((item) => {
          if (item.key == "total_lock_money") {
            this.totalMsg.total_lock_money = item.value;
          }
          if (item.key == "total_trading_volume") {
            this.totalMsg.total_trading_volume = item.value;
          }
        });
        this.$forceUpdate();
      });
    },
    toDeal() {
      this.$router.push("/deal");
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    //切换轮播图
    onChange(index) {
      this.current = index;
    },
    //轮播图
    getBanner() {
      this.$axios.get(this.$api.banner, {}).then((res) => {
        this.bannerList = res.data;
      });
    },
    //首页行情
    getMarket() {
      this.$axios.get(this.$api.market, {}).then((res) => {
        this.info = res.data;
      });
    },

    async getInfoData() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      //发起连接
      const account = await injectedProvider.request({
        method: "eth_requestAccounts",
      });
      //获取账户
      let accounts = null;
      injectedProvider
        .request({
          method: "eth_accounts",
        })
        .then(async (res) => {
          accounts = res[0];
          const provider = new ethers.providers.Web3Provider(injectedProvider);
          const accountBalance = await provider.getBalance(accounts);
          //钱包地址
          this.$store.commit("setwalletAddress", accounts);
          this.$storage.set("walletAddress", accounts);
          const signer = provider.getSigner();
          this.getmsgT(accounts, signer); //获取签名消息
        });
      //监听账户更改或断开事件
      injectedProvider.addListener("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
        } else {
          const newConnectedAccount = accounts[0];
        }
      });
      //监听链ID变化
      injectedProvider.addListener("chainChanged", (id) => {
        // console.log(id, 370); // => '0x1'
      });
      //获取链ID
      const chainId = await injectedProvider.request({ method: "eth_chainId" });
    },
    //获取签名数据
    getmsgT(e, signer) {
      let _this = this;
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.getmsg, {
          address: e,
        })
        .then((res) => {
          _this.getmsgIns(res.data.msg, e, signer);
        });
    },
    //请求签名
    async getmsgIns(msg, address, signer) {
      signer.signMessage(msg).then((result) => {
        this.$axios
          .post(this.$api.Access, {
            address: address,
            signature: result,
            code: "",
          })
          .then((res) => {
            this.$toast.clear();
            this.$storage.set("devotokens", res.data.auth);
            // this.reload();
          })
          .catch((err) => {
            Toast("failure");
          });
      });
    },
    getTrustWalletFromWindow() {
      const isTrustWallet = (ethereum) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;
        return trustWallet;
      };

      const injectedProviderExist =
        typeof window !== "undefined" && typeof window.ethereum !== "undefined";

      if (!injectedProviderExist) {
        return null;
      }

      if (isTrustWallet(window.ethereum)) {
        return window.ethereum;
      }

      if (window.ethereum?.providers) {
        return window.ethereum.providers.find(isTrustWallet) ?? null;
      }
      return window["trustwallet"] ?? null;
    },

    getPrice() {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
      });

      if (!connector.connected) {
        // create new session
        connector.createSession();
      } else {
      }

      const tx = {
        from: this.$store.state.walletAddress, // Required
        to: "0x01cf32A2c9DC4F4e2f4ab8a07624e3BB08193989", // Required (for non contract deployments)
        data: "0x38", // Required
        // gasPrice: "0x02540be400", // Optional
        // gas: "0x9c40", // Optional
        // value: "0x00", // Optional
        // nonce: "0x0114", // Optional
      };

      // Send transaction
      connector
        .sendTransaction(tx)
        .then((result) => {
          // Returns transaction id (hash)
        })
        .catch((error) => {
          // Error returned when rejected
        });
    },
    async switch_chain() {
      // 在提供者选项中设置链id和rpc映射
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              97: "",
            },
            chainId: 97,
          },
        },
      };
      // const web3Modal = new Web3Modal({
      //   network: "mainnet", // optional
      //   cacheProvider: true, // optional
      //   providerOptions, // required
      // });

      // const provider = await web3Modal.connect();
      // await web3Modal.toggleModal();

      // regular web3 provider methods
      const newWeb3 = new Web3(provider);
      const accounts = await newWeb3.eth.getAccounts();

      return accounts;
    },
    async getTW() {
      // 创建连接器
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
      });

      // 检查连接是否已经建立
      if (!connector.connected) {
        // create new session
        connector.createSession();
      } else {
      }

      // 监听连接事件
      connector.on("connect", async (error, payload) => {
        if (error) {
          throw error;
        }
        // 获取提供的账户和chainId
        const { accounts, chainId } = payload.params[0];
        window.localStorage.setItem("walletAddress", accounts[0]);
        this.$store.commit("setwalletAddress", accounts[0]); //钱包地址
        this.$store.commit("setLinkBsc", true);
        this.getmsg(accounts[0], connector);

        const currentChainId = payload.params[0].chainId;
        const web3 = new Web3();
        const cus = await web3.eth.getChainId();
      });

      let numId = this.switch_chain();
      try {
        await connector.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: numId }], // Ensure the selected network is Etheruem
        });
      } catch (e) {
        if (e.code === 4001) {
          setError("User rejected switching chains.");
        }
      }

      connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        // 获取更新的账户和chainId
        const { accounts, chainId } = payload.params[0];
      });
      //监听断开
      connector.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }
        // Delete connector
      });
    },
    getmsg(e, connector) {
      let _this = this;
      // this.$toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: "loading...",
      // });
      this.$axios
        .post(this.$api.getmsg, {
          address: e,
        })
        .then((res) => {
          _this.getmsgIn(res.data.msg, e, connector);
        });
    },
    getmsgIn(msg, e, connector) {
      const msgParams = [msg, e];
      setTimeout(() => {
        //签名
        connector
          .signPersonalMessage(msgParams)
          .then((result) => {
            // Returns signature.

            this.$axios
              .post(this.$api.Access, {
                address: e,
                signature: result,
                code: "",
              })
              .then((res) => {
                window.localStorage.setItem("devotokens", res.data.auth);
                this.reload();
              });
          })
          .catch((error) => {
            // Error returned when rejected
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobelAll {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.bottomBorder {
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  height: 10px;
}

.top {
  border-bottom: 1px solid #000;
  border-radius: 0 0 45px 0;
  // background-color: white;
  // margin: 0 15px;
  // padding: 0 10px;
  // padding-bottom: 5px;
  background: linear-gradient(90deg, #b79afa, #8867fe);
  .top_introduce {
    width: 100%;
    // border: 1px solid #000;
    // border-radius: 30px;
    // background-color: #e1e2ff;
    .topOne {
      width: 100%;
      height: 195px;
      border: 1px solid #000;
      border-radius: 30px;
      background-color: white;
      border-bottom: 1px solid #000;
    }
    img {
      width: 125px;
      height: 156px;
      margin-top: 10px;
    }
    .introduce_left {
      .connect-btn {
        border: none;
        // border-radius: 50px;
        width: 120px;
        height: 38px;
        margin-top: 10px;
        font-size: 12px;
        background: url("../assets/xxImage/index/bth@2x.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 12px;
      }
      .left_text {
        font-size: 13px;
        line-height: 20px;
      }
      .top_title {
        font-size: 18px;
        font-weight: bold;
        color: white;
        margin-bottom: 10px;
      }
    }
  }
}

.pledgeEnyaoq {
  width: 95%;
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 16px;
  background: linear-gradient(-90deg, #f7f2ff, #d3c2ff);
  border: 1px solid #000000;
  box-shadow: 1px 2px 0px 0px #000000;
  border-radius: 10px;

  .pledges {
    width: 100%;
    height: 65px;
    margin-bottom: 8px;
    // background-color: #8f93fb;
    // border-bottom: 1px solid #000;
    // border-radius: 15px;
    .pledge_item {
      width: 100%;
      // height: 59px;
      // background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 0;
      // border: 1px solid #000;
      // border-radius: 15px;
      .item_left {
        display: flex;
        align-items: center;
        div {
          margin-left: 10px;
          p:nth-of-type(1) {
            font-size: 12px;
            font-weight: bold;
          }
          p:nth-of-type(2) {
            margin-top: 5px;
            font-size: 10px;
            color: #a0a0a0;
          }
        }
      }
    }
    .right_item {
      width: 80px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../assets/xxImage/index/qianw.png") no-repeat;
      background-size: 100% 100%;
      color: white;
      font-size: 13px;
    }
  }
  .invite {
    width: 100%;
    // height: 65px;
    margin-bottom: 8px;
    // background-color: #8f93fb;
    // border-bottom: 1px solid #000;
    // border-radius: 15px;
    .pledge_item {
      width: 100%;
      height: 59px;
      // background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 0;
      // border: 1px solid #000;
      // border-radius: 15px;
      .item_left {
        display: flex;
        align-items: center;
        div {
          margin-left: 10px;
          p:nth-of-type(1) {
            font-size: 12px;
            font-weight: bold;
          }
          p:nth-of-type(2) {
            margin-top: 5px;
            font-size: 10px;
            color: #a0a0a0;
          }
        }
      }
    }
    .right_item {
      width: 80px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../assets/xxImage/index/qianw.png") no-repeat;
      background-size: 100% 100%;
      color: white;
      font-size: 13px;
    }
  }
}

.total {
  width: 95%;
  margin: 0 auto;
  height: 58px;
  background: linear-gradient(-90deg, #f7f2ff, #d3c2ff);
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px 0px #000000;
  border-radius: 10px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 0 21px;
  margin-top: 17px;
  .item {
    display: flex;
    align-items: center;
    flex: 1;
    .msg {
      div:first-child {
        font-size: 13px;
        font-family: Source Han Sans CN;
        margin-bottom: 7px;
        // font-weight: bold;
        color: black;
        white-space: nowrap;
      }
      div:last-child {
        font-size: 13px;
        font-family: Source Han Sans CN;
        // font-weight: bold;
        white-space: nowrap;
        color: black;
      }
    }
  }
  .line {
    width: 1px;
    height: 43px;
    background-color: #ffffff;
    opacity: 0.5;
    margin: 0 30px 0 33px;
  }
}

.brag {
  padding: 0 15px;
  width: 95%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(-90deg, #f7f2ff, #d3c2ff);
  border: 1px solid #000000;
  box-shadow: 1px 2px 0px 0px #000000;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 12px;
  .brag_left {
    height: 100%;
    // background-color: #ffffff;
    // border: 1px solid #000;
    // border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.49;
    p {
      margin-left: 10px;
      font-size: 13px;
      // font-weight: 600;
    }
  }
  .brag_right {
    height: 100%;
    // background-color: #ffffff;
    // border: 1px solid #000;
    // border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.49;
    p {
      margin-left: 10px;
      font-size: 13px;
      // font-weight: 600;
    }
  }
}
.XXXimg {
  width: 95%;
  height: 238px;
  margin: 0 auto;
  margin-top: 12px;
  // border: 1px solid #000;
  // border-radius: 10px;
  // padding: 5px 3px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .thisXXXimg {
    width: 100%;
    height: 100%;
    background: url("../assets/xxImage/index/tu2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .bottomFuo {
      width: 99%;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      text-align: center;
      line-height: 27px;
      border-radius: 22px;
      p:nth-of-type(1) {
        font-size: 15px;
        font-weight: bold;
      }
      p:nth-of-type(2) {
        font-size: 10px;
      }
      p:nth-of-type(3) {
        font-size: 10px;
      }
    }
  }
}
.community {
  width: 100%;
  padding: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 15px;
    font-weight: bold;
    color: black;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.metamask {
  height: 32px;
  background: #114996;
  border-radius: 5px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  margin-left: 21px;
}
.navbar {
  padding: 15px 16px;
  background: #fff;
  margin-bottom: 24px;
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.right_connect {
  height: 30px;
  border-radius: 24px;
}
.right_lang {
  height: 32px;
}
.connect-btn {
  .van-button__text {
    display: flex;
    align-items: center;
  }
}

.contents {
  .swipe {
    height: 180px;
    border-radius: 10px;
    margin-bottom: 33px;
  }
  .custom-indicator {
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    .item {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-left: 5px;
    }
    .item_active {
      width: 15px;
      height: 5px;
      border-radius: 3px;
      margin-left: 5px;
    }
  }
  .market {
    width: auto;
    overflow-x: scroll;
    .market_item {
      width: 90px;
      .img {
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
.jhshow {
  width: 236px;
  // height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 20px 0;
  p {
    font-size: 10px;
    font-weight: 500;
    color: black;
    margin-top: 20px;
  }
}
.jhshowBtn {
  width: 95%;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  div {
    width: 95px;
    height: 32px;
    border: 1px solid #000;
    border-radius: 5px;
    line-height: 32px;
    // margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}
.jhshowsw {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background: white;
  border-radius: 10px;
  z-index: 1000;
}
</style>