<template>
  <div class="content">
    <van-collapse v-model="activeNames" accordion :border="false">
      <van-collapse-item name="1" :border="false">
        <template #title>
          <div class="left-title">{{$t("index.about")}}</div>
        </template>
        <div class="value">
          <div>{{$t("index.document")}}</div>
          <div>{{$t("index.ReturnT")}}</div>
          <div>{{$t("index.news")}}</div>
          <div>GIthub</div>
          <div>{{$t("index.Loophole")}}</div>
          <div>{{$t("index.book")}}</div>
          <div>{{$t("index.term")}}</div>
          <div>{{$t("index.policy")}}</div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="2" :border="false">
        <template #title>
          <div class="left-title">{{$t("index.characteristic")}}</div>
        </template>
        <div class="value">
          <div>{{$t("index.exchange")}}</div>
          <div>{{$t("index.mobility")}}</div>
          <div>{{$t("index.Taiyue")}}</div>
          <div>{{$t("index.pool")}}</div>
          <div>MultI-Reward Pool</div>
          <div>{{$t("index.pledge")}}</div>
          <div>{{$t("index.analysis")}}</div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="3" :border="false">
        <template #title>
          <div class="left-title">{{$t("index.service")}}</div>
        </template>
        <div class="value">
          <div>{{$t("index.recommend")}}</div>
          <div>{{$t("index.Taiyue")}}</div>
          <div>{{$t("index.firing")}}</div>
          <div>{{$t("index.plan")}}</div>
          <div>{{$t("index.firmament")}}</div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="community">
      <p>{{$t("index.community")}}</p>
      <div class="imgs">
        <div @click="jump('telegram')">
          <van-image
            width="13px"
            height="11px"
            :src="require('@/assets/cls/telegram.png')"
            style="margin-right:19px;"
          />
        </div>
        <div @click="jump('twitter')">
          <van-image
            width="15px"
            height="12px"
            :src="require('@/assets/cls/twitter.png')"
            style="margin-right:19px;transform: translateX(1000px);filter: drop-shadow(-1000px 0px 0px #00A8FF);"
          />
        </div>

        <div @click="jump('Medium')">
          <van-image
            width="15px"
            height="12px"
            :src="require('@/assets/cls/Medium.png')"
            style="margin-right:19px;transform: translateX(1000px);filter: drop-shadow(-1000px 0px 0px #00A8FF);"
          />
        </div>
        <div @click="jump('website')">
          <van-image
            width="15px"
            height="15px"
            :src="require('@/assets/cls/website.png')"
            style="margin-right:19px;transform: translateX(1000px);filter: drop-shadow(-1000px 0px 0px #00A8FF);"
          />
        </div>
      </div>
      <div class="btns">
        <div>
          <van-image width="28px" height="28px" :src="require('@/assets/cls/Marketplace.png')" />
          <span>Marketplace</span>
        </div>
        <div>
          <van-image width="104px" height="28px" :src="require('@/assets/cls/Certik.png')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: "",
      setting: {}
    };
  },
  methods: {
    jump(val) {
      switch (val) {
        case "telegram":
          window.location.href = this.setting.telegram_url;
          console.log(this.setting.telegram_url);
          break;
        case "twitter":
          window.location.href = this.setting.twitter_url;

          break;
        case "Medium":
          window.location.href = `mailto:${this.setting.email}` ;

          break;
        case "website":
          window.location.href = this.setting.web_site;

          break;
      }
    },
    msgHandle() {
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach(item => {
        if (item.key == "web_site") {
          this.setting.web_site = item.value;
        }
        if (item.key == "twitter_url") {
          this.setting.twitter_url = item.value;
        }
        if (item.key == "telegram_url") {
          this.setting.telegram_url = item.value;
        }
        if (item.key == "email") {
          this.setting.email = item.value;
        }
      });
    }
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.msgHandle();
  }
};
</script>
<style lang="scss" scoped>
.content {
  background: #183b79;
  padding-bottom: 17px;
  overflow-x: hidden;
  .van-collapse {
    .left-title {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    /deep/.van-collapse-item__title {
      background: #183b79;
    }
    /deep/.van-collapse-item__content {
      background: #183b79;
    }
    .value {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #00a8ff;
      div {
        margin-bottom: 17px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .community {
    padding: 16px 15px 0;
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .imgs {
      margin: 16px 0 21px;
      display: flex;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46%;
        height: 50px;
        background: #114996;
        border-radius: 5px;
        span {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin-left: 11px;
        }
      }
    }
  }
}
</style>